import { useEffect, useState } from 'react';
import { AppBar, Button, CardMedia, Divider, Grid, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { getDetailsWithReferenceId, getOtpForInvestor } from '../../redux-store/actions/onBoarding';
import {
  GetDetailsFromRefIDResponse,
  GetOtpForInvestorResponse,
} from '../../redux-store/types/api-types';
import { Footer } from '../commonComponents';
import { currencyConversion } from '../../utils/utilityFunctions';

export default function InvestmentDetails(): JSX.Element {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [details, setDetails] = useState<GetDetailsFromRefIDResponse>({
    schemeName: '',
    planDescription: '',
    commitmentAmount: 0,
    contributionChequeAmount: 0,
    managementFeesRate: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { referenceId } = params as unknown as { referenceId: string };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setIsLoading(true);
        const response = (await dispatch(
          getDetailsWithReferenceId(referenceId)
        )) as unknown as GetDetailsFromRefIDResponse;
        if (!isComponentAlive) return;
        setDetails(response);
        setIsLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const getDetails = (heading: string, detail: string | number): JSX.Element => {
    return (
      <Grid item xs={12} md={6}>
        <Typography gutterBottom>{heading}</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Typography variant="body2">
            {['Commitment Amount', 'Initial Contribution Amount'].includes(heading)
              ? currencyConversion(detail) || '-'
              : ['Setup Fee Percentage', 'Management Fees Rate'].includes(heading)
              ? Number(detail) === 0
                ? '0'
                : detail || '-'
              : detail || '-'}
          </Typography>
        )}
      </Grid>
    );
  };

  const verifyUser = async () => {
    try {
      const response = (await dispatch(
        getOtpForInvestor(referenceId)
      )) as unknown as GetOtpForInvestorResponse;
      history.push('verify-pan', {
        ...(response || {}),
        mobile: (response || {}).phone,
        email: (response || {}).email,
        loginType: 'investor',
        referenceId,
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="static" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box>
          <CardMedia
            component="img"
            src="/images/mo-assetManagement-logo.svg"
            alt="Logo"
            sx={{ width: '120px', m: 3, my: 1 }}
          />
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box
        component="main"
        sx={{
          height: { xs: '', sm: 'calc(100vh - 131px)' },
          pt: { xs: '', sm: '30px' },
        }}>
        <Box
          sx={{
            bgcolor: 'common.white',
            height: { xs: '', sm: 'calc(100vh - 200px)' },
            overflowY: 'auto',
            width: 'clamp(300px, 90%, 1200px)',
            mx: 'auto',
            py: '40px',
            px: { xs: '30px', sm: '80px' },
            borderRadius: '10px',
            boxShadow: { xs: '', sm: '0px 4px 12px rgba(0, 0, 0, 0.05)' },
          }}>
          <Box sx={{ width: { xs: '100%', sm: '90%' }, mx: 'auto' }}>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                mb: 4,
                alignSelf: 'flex-start',
              }}>
              Investment Details
            </Typography>
            <Box
              sx={{
                boxShadow: { xs: '', sm: '0px 0px 5px rgba(0, 0, 0, 0.2)' },
                borderRadius: '10px',
                p: '20px',
                my: 2,
              }}>
              <Grid
                container
                // rowSpacing={5}
                // columnSpacing={10}
                sx={{
                  '.MuiGrid-item': {
                    px: { xs: '', sm: '70px' },
                    py: '20px',
                  },
                  '.MuiTypography-root': {
                    fontSize: 16,
                    letterSpacing: '0.05em',
                    '&.MuiTypography-body2 ': { fontWeight: 600 },
                  },
                }}>
                {[
                  { heading: 'Fund(Scheme)', key: 'schemeName' },
                  { heading: 'Class(Plan)', key: 'planDescription' },
                  { heading: 'Commitment Amount', key: 'commitmentAmount' },
                  { heading: 'Setup Fee Percentage', key: 'setupFeePercentage' },
                  { heading: 'Management Fees Rate', key: 'managementFeesRate' },
                  { heading: 'Initial Contribution Amount', key: 'contributionChequeAmount' },
                ].map(({ heading, key }) => getDetails(heading, details[key]))}
              </Grid>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  mt: 5,
                  fontSize: 14,
                  lineHeight: 1.5,
                  maxWidth: '300px',
                }}
                disabled={isLoading}
                onClick={verifyUser}>
                Verify
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
